<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5 ml-2">
      <h3 class="card-title align-items-start flex-column">

      </h3>
      <div class='d-flex justify-content-end'>
        <div class="card-toolbar">
          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createTransactionClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny Transaktionskostnad</a
          >
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-header border-0 py-5 m-1">
      <div>
        <b-form-select v-model="perPage" :options="options"></b-form-select>
      </div>
      <b-pagination
        class="ml-auto mb-0"
        v-model="currentPage"
        :total-rows="transactions.length"
        :per-page="perPage"
        aria-controls="transaction-table"
        first-number
        last-number
      ></b-pagination>
    </div>

    <div class="card-body pt-0">
      <b-table
        id="transaction-table"
        ref="transactionTable"
        :fields="headers"
        :items="transactions"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="light"
        class="mh-100"
        sticky-header
        selectable
        selected-variant=""
        @row-selected="onRowSelected"
      >

       <template #head(selected)>
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input type="checkbox" name="" :checked="allSelected" @click="toggleRows" />
              <span class="border-dark"></span>
            </label>
          </div>
        </template>

        <template #cell(selected)="data">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                :checked="data.rowSelected"
                @click="selectRow(data.index)"
              />
              <span></span>
            </label>
          </div>
        </template>
      </b-table>
    </div>

      <!--end::Table-->
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import ExportTHSTransactionsModal from '@/view/pages/ml/transactions/ExportTHSTransactionsModal.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: "transaction-table",
  components: {
    ExportTHSTransactionsModal,
  },
  emits: ['createTransactionClicked', 'selectTransactionClicked', 'deleteTransactionClicked'],
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS']),
  },
  mounted() {

  },
  mixins: [ toasts ],
  methods: {
    async loadUnbookedTransactions() {
      axios
        .get(`/transaction/nonbooked`)
        .then(res => {
          if (res.status !== 200) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Fel att hämta transaktioner');
            return;
          }

          this.transactions = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista transaktioner');
        });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    search(from_date, to_date, ths_invoice_nr, ths_is_invoiced) {
      const url =
        ths_is_invoiced ?
          `/transaction/thsinvoice/${ths_invoice_nr}` :
          `/transaction?from_date=${from_date}&to_date=${to_date}&ths_is_invoiced=${ths_is_invoiced}`;

      axios
        .get(url)
        .then(res => {
          this.transactions = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte söka bland transaktioner');
        });
    },
    createTransactionClicked() {
      this.$emit('createTransactionClicked');
    },
    selectTransactionClicked(id) {
      this.$emit('selectTransactionClicked', id);
    },
    deleteTransactionClicked(id) {
      this.$emit('deleteTransactionClicked', id);
    },
    selectRow(index) {
      if (this.$refs.transactionTable.isRowSelected(index)) {
        this.$refs.transactionTable.unselectRow(index);
      } else {
        this.$refs.transactionTable.selectRow(index);
      }
    },
    toggleRows() {

      this.$refs['transactionTable'].selectAllRows();

      if (this.allSelected) {
        this.$refs.transactionTable.clearSelected();
        this.allSelected = false;
      } else {

        this.allSelected = true;
      }
    },

  },
  data() {
    return {
      selected: [],
      allSelected: false,
      transactions: [],
      showAll: false,
      currentPage: 1,
      perPage: 100,
      options: [
        100, 150, 200
      ],
      headers: [
        {
          key: 'selected',
          label: 'Vald',
          sortable: false
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'reference',
          label: 'Referens',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'cost_sek',
          label: 'Summa',
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Datum',
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
      ]
    };
  }
};
</script>
