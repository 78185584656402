<template>

  <b-modal ref="modal" v-model="showExportOptionsModal" hide-footer>
    <b-row v-if="showError">
        <b-col lg="12">
            <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
    </b-row>

    <b-row>
        <b-col lg="6">
          <memlist-date-picker
            v-model="created_from"
            class="mb-8"
            placeholder="Transaktioner fr.o.m"
          />
        </b-col>
        <b-col lg="6">
          <memlist-date-picker
            v-model="created_to"
            class="mb-8"
            placeholder="Transaktioner t.o.m"
          />
        </b-col>
    </b-row>
    <b-row>
        <b-col>

            <b-form-group id="input-group-service" label="Tjänst" label-for="input-service">
              <TransactionServicesSelect
                ref="serviceSelect"
              />
            </b-form-group>

        </b-col>
        <b-col>
            <b-form-group id="input-group-module" label="Modul" label-for="input-module">
              <TransactionModulesSelect
                ref="modulesSelect"
              />
            </b-form-group>
        </b-col>
    </b-row>


    <b-card
      title="Fakturakoppling"
    >
        <b-row>
        <b-col>
            <b-form-group label="Fakturanummer" label-for="person-ths_invoice_nr">
                <b-form-input autocomplete="off"
                id="ths_invoice_nr"
                v-model="ths_invoice_nr"
                :state="ths_invoice_nr_valid"
                placeholder="Siffra"
                />
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Fakturadatum" label-for="person-ths_invoiced_at">
              <memlist-date-picker
                v-model="ths_invoiced_at"
                class="mb-8"
                placeholder="Fakturadatum"
                :state="ths_invoiced_at_valid"
              />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row class="mt-6">
        <b-col lg="6">
        <b-button variant="outline-success" :disabled="inputValid" @click="save">
          {{$t('COMMON.CONTINUE')}}
        </b-button>
        </b-col>
        <b-col lg="6">
        <b-button variant="outline-primary" @click="cancel">
          {{$t('COMMON.CANCELs')}}
        </b-button>
        </b-col>
    </b-row>
    </b-card>


  </b-modal>

</template>
<style lang="scss" scoped>
</style>
<script>
import axios from 'axios';

import TransactionServicesSelect from '@/view/pages/ml/transactions/TransactionServicesSelect.vue';
import TransactionModulesSelect from '@/view/pages/ml/transactions/TransactionModulesSelect.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ExportTHSTransactionsModal',
  components: {
    TransactionServicesSelect,
    TransactionModulesSelect
  },
  mixins: [ toasts ],
  data() {
    return {
      ths_invoiced_at: null,
      ths_invoice_nr: null,
      statusOptions: [
          { value: 'ALL', text: this.$t('COMMON.ALL') },
          { value: 'ACTIVE', text: this.$t('COMMON.ACTIVE') },
          { value: 'DONE', text: this.$t('COMMON.DONE') },
      ],
      created_from: null,
      created_to: null,
      status: 'ALL',
      showError: false,
      errorText: this.$t('COMMON.AN_ERROR_OCCURED'),
      showExportOptionsModal: false,
    };
  },
  props: {

  },
  emits: ['cancel'],
  watch: {
  },
  computed: {
    ths_invoiced_at_valid() {
      return this.ths_invoiced_at !== null;
    },
    ths_invoice_nr_valid() {
      const test = parseInt(this.ths_invoice_nr+'');

      if (isNaN(test)) {
        return false;
      }

      return true;
    },
    inputValid() {
      return !(this.created_from !== null && this.created_to !== null && this.ths_invoiced_at_valid && this.ths_invoice_nr_valid );
    }
  },
  async mounted() {

  },
  methods: {
    show() {
      this.showExportOptionsModal = true;
    },
    close() {
      this.showExportOptionsModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    save() {
      const body = {
        modules: this.$refs['modulesSelect'].getData(),
        services: this.$refs['serviceSelect'].getData(),

        from_date: this.created_from,
        to_date: this.created_to,

        ths_invoice_nr: parseInt(this.ths_invoice_nr+''),
        ths_invoiced_at: this.ths_invoiced_at
      }

      this.postXlsx(body);
    },

    postXlsx(body) {
      axios
        .post('/transaction/xlsx', body)
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
            this.close();
          }
          else {
            console.error(res.data);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda hem export');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa export');
        });
    },

  }
};
</script>
<style scoped>
</style>

